<template>
    <div class="view pa24">
        <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList"><i
                class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName }}</span> <i
                class="el-icon-caret-bottom  fs12 ml5"></i></div>
        <el-tabs v-model="searchData.pdfType" @tab-click="searchFun">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane :label="name" :name="String(id)" v-for="(name, id) in pdfTypeData" :key="id"></el-tab-pane>
        </el-tabs>
        <div class="d-flex mt20">
            <div>
                <el-input class="w400 mr10" placeholder="请输入PDF名称关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button type="primary" @click="mergePdfFiles" :disabled="!checkedCompany.pcId">合并PDF{{ checkedPdf.length
                    > 0 ? '：已选中' + checkedPdf.length
                + '条' : ''
                }}</el-button>
                <el-button type="primary" class="pdfBtn" @click="showAddPdfFun"
                    :disabled="!checkedCompany.pcId">生成PDF</el-button>
                <el-button type="primary" class="pdfBtn" @click="showCreatePdfFun"
                    :disabled="!checkedCompany.pcId">WORD转PDF</el-button>

            </div>
        </div>
        <el-table class="mt20" v-loading="loading" ref="multipleTable" :data="tableData" border lay tooltip-effect="dark"
            @select="selectPdf" @select-all="selectPdfAll">
            <!-- <el-table-column type="index" align="center" label="序号" /> -->
            <el-table-column type="selection" width="55" />
            <el-table-column prop="fileName" align="center" label="文件名称" show-overflow-tooltip />
            <el-table-column prop="projectName" align="center" label="项目名称" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.projectName ? scope.row.projectName : '-' }}
                </template>
            </el-table-column>
            <el-table-column prop="userName" align="center" label="操作用户" show-overflow-tooltip width="100" />
            <el-table-column prop="createTime" align="center" label="转换时间" show-overflow-tooltip width="120">
                <template slot-scope="scope">
                    {{ scope.row.createTime.slice(0, 10) }}
                </template>
            </el-table-column>
            <el-table-column prop="size" align="center" label="文件大小" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    {{ (scope.row.size / (1024 * 1024)).toFixed(2) === "0.00" ? "0.01MB" : (scope.row.size / (1024 *
                        1024)).toFixed(2) + 'MB' }}
                </template>
            </el-table-column>
            <el-table-column prop="pdfType" align="center" label="生成类型" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    {{ pdfTypeData[scope.row.pdfType] }}
                </template>
            </el-table-column>
            <el-table-column prop="fileUrl" align="center" label="查看" show-overflow-tooltip width="50">
                <template slot-scope="scope">
                    <a :href="scope.row.fileUrl" class="cblue" target="_blank" title="PDF查看"><i
                            class="iconzulinhetong iconYQH" style="color:#eb7d6b;"></i> </a>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120">
                <template slot-scope="scope">
                    <el-button type="text" @click="compressPdf(scope.row)" v-if="scope.row.pdfType != 1">压缩
                    </el-button>
                    <el-button style="color:#F56C6C" type="text" @click="delProjectPdf(scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>

        </el-table>
        <div style="display: flex; justify-content: flex-end; margin-top: 16px" v-if="tableData.length !== 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
                class="" :total="total" :pager-count="5" />
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="自动生成PDF" center width="500px"
            :visible.sync="showAddPdf">
            <div class="setWordView">
                选择模板和研发项目填写内容，生成文档
                <div class=" e6a23c">
                    选择研发项目或参考内容会覆盖掉之前填写的内容
                </div>
                <div class="selectCompany mt10 mb20 flex-a-c"><i class="el-icon-office-building fs12 mr5"></i>{{
                    checkedCompany.companyName }}</div>
                <div class="selectWord" @click="showWordTable">
                    <i v-if="addPdfDataLoading" class="el-icon-loading"></i>
                    <i v-else class="el-icon-upload"></i>
                    <div class="el-upload__text" v-if="fileName">
                        选择Word文档， <em>点击选择</em>
                        <div class="mt10" v-if="checkedWordName">
                            {{ checkedWordName }}，转换中...
                        </div>
                        <div v-else>自动转换PDF</div>
                    </div>
                </div>
                <!-- <div class="mt40 flex-c-c">
                    <el-button type="primary" plain @click="showAddPdf = false">
                        取消
                    </el-button>
                    <el-button size="small" type="primary" class="pdfBtn" @click="addPdf"
                        :loading="addPdfDataLoading">生成PDF</el-button>
                </div> -->
            </div>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="上传文件转PDF" center width="420px"
            :visible.sync="showCreatePdf" @close="hideCreatePdfFun">
            <p class="mb10">上传word文档转PDF</p>
            <div class="selectCompany mt10 mb20 flex-a-c"><i class="el-icon-office-building fs12 mr5"></i>{{
                checkedCompany.companyName }}</div>
            <div class="d-flex mb20">
                <el-upload action="#" accept=".docx" multiple :auto-upload="false" :show-file-list="false"
                    :on-change="handleUpload" :file-list="fileList" :on-remove="delWordFile" drag>
                    <i v-if="checkedPdfDataLoading" class="uploadWord el-icon-loading"></i>
                    <i v-else class="el-icon-upload"></i>
                    <div class="el-upload__text">
                        将文件拖到此处，或 <em>点击上传</em>
                        <div>
                            只能上传docx格式文件
                        </div>
                    </div>
                </el-upload>
            </div>
            <!-- <div v-if="pdfData.length > 0">
                <el-divider content-position="left" class="mb20">生成的PDF文件</el-divider>
                <div class="ml20">
                    <div class="mt5" v-for="(row, index) in pdfData" :key="index">
                        <a :href="row.url" class="cblue" target="_blank">{{
                            row.name
                        }}</a>
                    </div>
                </div>
            </div> -->
        </el-dialog>
        <word-table ref="wordTable" @confirmFun="selectWord"></word-table>
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
    </div>
</template>

<script>
import wordTable from "@/pages/projectAdmin/components/wordTable";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import { getProjectPdfList, delProjectPdf, compressPdf, word2Pdf, mergePdfFiles, uploadPdfFiles } from "@/api/projectAdmin";
let base64 = require('js-base64').Base64
export default {
    name: "groupingTable",
    components: {
        wordTable,
        selectCompanyList
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            loadingModel: false,
            tableData: [],
            searchData: { name: "", pdfType: 'all' },
            userInfo: this.$store.state.loginRoot.userInfo,
            //导入模板配置
            showAddPdf: false,
            modelContent: {
                html: "",
                normal: [],
                list: []
            },
            //生成文档配置
            checkedWordName: "",
            remark: "",
            showNumber: 0,
            param: {},
            formData: {
                dataId: ""
            },
            addPdfDataLoading: false,
            checkedCompany: {},
            checkedPdf: [],
            pdfTypeData: {
                0: 'Word转换',
                1: '压缩',
                2: '合并',
                3: '上传文件生成'
            },
            //word转PDF
            fileList: [],
            showCreatePdf: false,
            fileName: "点击上传",
            checkedPdfDataLoading: false,
            uploadFile: [],
            pdfData: [],
        };
    },
    computed: {
        getRowNumber() {
            return (str) => {
                let rows = Math.ceil(String(str).length / 100)
                return rows ? rows : 1;
            }
        }
    },
    mounted() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        this.getList();
    },
    methods: {
        handleUpload(file) {
            this.uploadFile = [file.raw];
            this.createPdf();
        },
        delWordFile(file, fileList) {
            this.uploadFile = [];
        },
        hideCreatePdfFun() {
            this.showCreatePdf = false;
            this.pdfData = [];
        },
        showCreatePdfFun() {
            this.showCreatePdf = true;
        },
        createPdf() {
            let parmas = {
                pcId: this.checkedCompany.pcId
            };
            if (this.uploadFile.length <= 0) {
                this.$message.error("请上传word文档");
                return;
            }
            // this.$message.info("生成PDF中请稍后...")
            this.checkedPdfDataLoading = true;
            uploadPdfFiles(parmas, { fileList: this.uploadFile }).then(res => {
                this.checkedPdfDataLoading = false;
                if (res.code === 200) {
                    this.currentPage = 1;
                    this.searchData.name = "";
                    this.pdfData = res.data;
                    this.fileList = [];
                    this.uploadFile = [];
                    this.getList();
                    this.hideCreatePdfFun();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.checkedPdfDataLoading = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 单选PDF */
        selectPdf(selection, row) {
            let ids = [];
            for (let item of this.checkedPdf) {
                ids.push(item.pdfId);
            }
            if (ids.indexOf(row.pdfId) != -1) {
                this.checkedPdf.splice(ids.indexOf(row.pdfId), 1)
            } else {
                this.checkedPdf.push(row);
            }
        },
        /**@method 全选 */
        selectPdfAll(selection) {
            if (selection[0] && selection.length > 0) {
                let ids = [];
                for (let item of this.checkedPdf) {
                    ids.push(item.pdfId);
                }
                for (let row of selection) {
                    if (row && ids.indexOf(row.pdfId) == -1) {
                        this.checkedPdf.push(row);
                    }
                }
            } else {
                for (let row of this.tableData) {
                    for (let index in this.checkedPdf) {
                        let item = this.checkedPdf[index]
                        if (item.pdfId == row.pdfId) {
                            this.checkedPdf.splice(index, 1)
                        }
                    }
                }
                this.$refs.multipleTable.clearSelection();
            }
        },
        /**@method 选中状态 */
        checkedPdfStatus() {
            this.$nextTick(() => {
                for (let row of this.checkedPdf) {
                    this.$refs.multipleTable.toggleRowSelection(this.tableData.find(item => {
                        return item.pdfId == row.pdfId;
                    }), true);
                }
            })
        },
        /**@method 显示文档数据 */
        showWordTable() {
            this.$refs.wordTable.showModelFun();
        },
        /**@method 选中文档数据 */
        selectWord(data) {
            this.checkedWordName = data.modelName;
            this.formData.dataId = data.dataId;
            this.$refs.wordTable.hideModelFun();
            this.addPdf()
        },
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.searchData.name = "";
            this.searchData.pdfType = "all";
            this.searchFun();
        },
        /**@method 生成PDF */
        addPdf() {
            if (!this.addPdfDataLoading) {
                this.addPdfDataLoading = true;
                let params = {
                    dataId: this.formData.dataId,
                    pcId: this.checkedCompany.pcId
                };
                word2Pdf(params).then(res => {
                    this.addPdfDataLoading = false;
                    if (res.code === 200) {
                        this.checkedWordName = "";
                        this.currentPage = 1;
                        this.searchData.name = "";
                        this.getList();
                    } else {
                        this.$message.error(res.message)
                    }
                }).catch(err => {
                    this.addPdfDataLoading = false;
                    if (err.code === 201) {
                        this.$message.error(err.message)
                    }
                })
            }
        },
        /**@method 显示生成PDF */
        showAddPdfFun() {
            this.showAddPdf = true;
            this.checkedWordName = ""
            this.formData = {
                dataId: ""
            };
            this.remark = "";
            if (this.showNumber == 0) {
                this.$nextTick(() => {//附加模板列表添加/删除行事件
                    $(".setWordView").on("click", ".addRow", function () {
                        let $tr = $(this).parents("tr");
                        $tr.after("<tr>" + $tr.html() + "</tr>");
                        $tr.next().find(".isList").html("");
                    })
                    $(".setWordView").on("click", ".delRow", function () {
                        $(this).parents("tr").remove();
                    })
                })
                this.showNumber++;
            }


        },
        /**@method 合并PDF */
        mergePdfFiles() {
            if (this.checkedPdf.length < 2) {
                this.$message.warning("请至少选择二条PDF数据");
                return;
            }
            let pdfIds = [];
            for (let row of this.checkedPdf) {
                pdfIds.push(row.pdfId);
            }
            let params = {
                pdfIds: pdfIds.join(","),
                pcId: this.checkedCompany.pcId
            };
            this.$message.info('合并中请稍后...');
            mergePdfFiles(params).then(res => {
                if (res.code === 200) {
                    this.searchData.name = "";
                    this.searchFun();
                    // window.open(res.data);
                    let link = document.createElement('a');
                    link.href = res.data;
                    link.click();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 压缩PDF */
        compressPdf(row) {
            let params = {
                pdfId: row.pdfId,
                qualityNum: 10,
                pcId: this.checkedCompany.pcId
            }
            this.$message.info('压缩中请稍后...');
            compressPdf(params).then(res => {
                if (res.code === 200) {
                    this.searchData.name = "";
                    this.searchFun();
                    // window.open(res.data);
                    let link = document.createElement('a');
                    link.href = res.data;
                    link.click();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 删除PDF */
        delProjectPdf(row) {
            this.$confirm("确定要继续删除这条PDF文件数据", "删除PDF文件", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                let params = {
                    pdfId: row.pdfId
                }
                delProjectPdf(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('删除完成');
                        this.searchData.name = "";
                        this.searchFun();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                fileName: this.searchData.name,
            };
            if (!this.checkedCompany.pcId) {
                this.tableData = [];
                this.total = 0
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            if (this.searchData.pdfType != 'all') {
                params.pdfType = this.searchData.pdfType
            }
            try {
                this.loading = true;
                let res = await getProjectPdfList(params)
                this.loading = false;
                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    tableData.push({
                        ...row
                    })
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
                this.checkedPdfStatus();
            } catch (error) {
               //-console.log(error);
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>
<style lang="scss" scoped>
.uploadWord.el-icon-loading {
    font-size: 67px;
    color: #c0c4cc;
    margin: 40px 0 16px;
    line-height: 50px;
}

.selectWord {
    margin: 0 auto;
    padding-top: 30px;
    text-align: center;
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 360px;
    height: 180px;
    text-align: center;
    position: relative;
    overflow: hidden;
    color: #606266;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 30px;

    em {
        color: #51CDCB;
        font-style: normal;
    }

    i {
        font-size: 67px;
        color: #C0C4CC;
    }
}

.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
}

.showModelContent {
    overflow-x: scroll;
    max-width: 842px;
    margin: auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    padding: 20px;
}


.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>